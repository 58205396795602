$itemHeight: 2.5rem;

.wrapper {
  max-width: 480px;
  background: var(--color-interactive-element-hover);
  border-radius: 6px;
  margin-top: 1rem;
  max-height: calc(#{$itemHeight} * 8);
  overflow-y: overlay;
}

.folderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  max-height: $itemHeight;
  cursor: pointer;

  &:hover {
    background: var(--color-background-compact-menu-hover);
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  gap: 0.625rem;
  justify-content: center;
}

.folderIcon {
  font-size: 1.25rem;
}

.folderTitle {
  flex-grow: 1;
  color:var(--color-text);
  font-size: 16px;
  line-height: 24px;
}

.checkmark {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray);

  &.selected {
    background: var(--color-primary);
    border-radius: 50%;

    i {
      color: var(--color-white);
    }
  }
}
