.DropdownMenu {
  position: relative;

  .ProfilePhoto-UluHeaderProfile {
    border-radius: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    }
    .Placeholder-UluHeaderProfile {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      background: var(--color-interactive-element-hover);
      color: var(--color-text);
      font-size: 14px;
      font-weight: bold;
    }

    .ProfilePhoto {
    background-color: var(--color-interactive-element-hover);
    border-radius: 0.5rem;
    
    
    .thumb:not(.shown) {
      display: none !important; 
    }

  }
}
.thumb:not(.shown) {
  display: none !important; 
}

