.theme-light {
  --button-shadow: var(--button-shadow-light);
}

.theme-dark {
  --button-shadow: var(--button-shadow-dark);
}


@mixin active-styles() {
  &:not(.disabled):not(:disabled) {
    &:active,
    &.active,
    &:focus {
      @content;
    }
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  }
}

@mixin no-ripple-styles() {
  body.no-page-transitions & {
    &:not(.disabled):not(:disabled) {
      &:active {
        @content;
      }
    }
  }
}

.Button {
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.5rem;
  border: 0;
  border-radius: var(--border-radius-default);
  background-color: transparent;
  background-size: cover;
  padding: 0.625rem;
  color: white;
  line-height: 1.2;
  cursor: var(--custom-cursor, pointer);
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: background-color 0.15s, color 0.15s;
  text-decoration: none !important;

  --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);

  // @optimization
  &:active,
  &.clicked,
  body.no-page-transitions & {
    transition: none !important;
  }

  &.disabled {
    cursor: var(--custom-cursor, default);

    &:not(.non-interactive) {
      opacity: 0.5 !important;
    }

    &:not(.click-allowed) {
      pointer-events: none;
    }
  }

  &.round {
    width: 3.5rem;
    border-radius: 6px;
    cursor: pointer;

    .icon {
      font-size: 1.5rem;
    }
  }

  &.primary {
    box-shadow: var(--button-shadow);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.025) 0%, rgba(255, 255, 255, 0) 100%), var(--color-primary);
    color: #e3e3e3;
    --ripple-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;


    @include active-styles() {
      box-shadow: var(--button-shadow);
      background: linear-gradient(180deg, rgb(255 255 255 / 20%), rgb(255 255 255 / 5%) 100%), var(--color-primary);
      cursor: pointer;
    }

    @include no-ripple-styles() {
      box-shadow: var(--button-shadow);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.125) 0%, rgba(255, 255, 255, 0.00) 100%), var(--color-primary);
      cursor: pointer;
    }
  }

  &.secondary {
    background-color: var(--color-background);
    color: var(--color-text);
    --ripple-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;

    @include active-styles() {
      background-color: var(--color-interactive-element-hover);
      cursor: pointer;
    }

    @include no-ripple-styles() {
      background-color: var(--color-primary-shade);
      cursor: pointer;
    }
  }

  &.gray {
    background-color: var(--color-background);
    color: var(--color-text-secondary);
    --ripple-color: rgba(0, 0, 0, 0.08);

    @include active-styles() {
      color: var(--color-primary);
    }

    @include no-ripple-styles() {
      background-color: var(--color-chat-hover);
    }
  }

  &.danger {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0) 100%) ,var(--color-error);
    color: #e3e3e3;
    --ripple-color: rgba(var(--color-error-rgb), 0.16);
    box-shadow: var(--button-shadow);
    cursor: pointer;

    @include active-styles() {
      background: linear-gradient(180deg, rgb(255 255 255 / 20%), rgb(255 255 255 / 5%) 100%),var(--color-error);
      color: #e3e3e3;
      box-shadow: var(--button-shadow);
      cursor: pointer;
    }

    @include no-ripple-styles() {
      color: #e3e3e3;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.125), rgba(255, 255, 255, 0) 100%), var(--color-error);
      box-shadow: var(--button-shadow);
      cursor: pointer;
    }
    &.danger:hover {
      color: #e3e3e3;
    }
  }

  &.text {
    // background-color: transparent;

    &.primary {
      color: var(--color-text);
      background-color: rgb(255 255 255 / 34%);
      cursor: pointer;

      @include active-styles() {
        background-color: rgba(255,255,255,0.04);
        cursor: pointer;
      }

      @include no-ripple-styles() {
        background-color: rgba(var(--color-primary-shade-rgb), 0.16);
        cursor: pointer;
      }
    }

    &.secondary {
      background-color: transparent;
      color: var(--color-text);
      cursor: pointer;
    }

    &.danger {
      @include active-styles() {
        background: linear-gradient(180deg, rgb(255 255 255 / 20%), rgb(255 255 255 / 5%) 100%),var(--color-error);
        color: var(--color-text);
        box-shadow: var(--button-shadow);
        cursor: pointer;
      }

      @include no-ripple-styles() {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.125), rgba(255, 255, 255, 0) 100%), var(--color-error);
        cursor: pointer;
      }
    }
  }

  &.faded {
    opacity: 0.8;

    @include active-styles() {
      opacity: 1;
    }

    &.activated {
      opacity: 1;
    }
  }

  &.translucent {
    --ripple-color: var(--color-interactive-element-hover);
    background-color: transparent;
    color: var(--color-text-secondary);

    @include active-styles() {
      background-color: var(--color-interactive-element-hover);
    }

    @include no-ripple-styles() {
      background-color: rgba(var(--color-text-secondary-rgb), 0.16);
    }

    &.activated {
      color: var(--color-primary);
    }
  }

  &.translucent-white {
    --ripple-color: rgba(255, 255, 255, 0.08);
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);

    @include active-styles() {
      background-color: rgba(255, 255, 255, 0.08);
      color: white;
    }

    @include no-ripple-styles() {
      background-color: rgba(255, 255, 255, 0.16);
    }
  }

  &.translucent-black {
    --ripple-color: rgba(0, 0, 0, 0.08);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);

    @include active-styles() {
      background-color: rgba(0, 0, 0, 0.08);
    }

    @include no-ripple-styles() {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }

  &.translucent-bordered {
    --ripple-color: rgba(0, 0, 0, 0.08);
    background-color: transparent;
    color: var(--accent-color);
    border: 1px solid var(--accent-color);

    @include active-styles() {
      background-color: var(--accent-color);
      color: var(--color-white);
    }

    @include no-ripple-styles() {
      background-color: var(--active-color);
    }
  }

  &.adaptive {
    --ripple-color: var(--accent-background-active-color);
    background-color: var(--accent-background-color);
    color: var(--accent-color);

    @include active-styles() {
      background-color: var(--accent-background-active-color);
    }

    @include no-ripple-styles() {
      background-color: var(--accent-background-active-color);
    }
  }

  &.dark {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    --ripple-color: rgba(255, 255, 255, 0.08);

    @include active-styles() {
      background-color: rgba(0, 0, 0, 0.85);
      color: white;
    }

    @include no-ripple-styles() {
      background-color: rgba(0, 0, 0, 0.95);
    }
  }

  &.green {
    background-color: var(--color-green);
    color: var(--color-white);
    --ripple-color: rgba(0, 0, 0, 0.08);

    @include active-styles() {
      background-color: var(--color-green-darker);
    }

    @include no-ripple-styles() {
      background-color: var(--color-green);
    }
  }

  &.smaller {
    height: 2.75rem;
    padding: 0.3125rem;

    &.round {
      width: 2.75rem;
    }

    &.pill {
      height: 2.5rem;
      border-radius: 1.25rem;
      padding: 0.3125rem 1rem;
    }

    &.with-icon {
      padding-left: 0.75rem;
      padding-right: 1.25rem;

      .icon {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }

      &[dir="rtl"] {
        padding-left: 1.25rem;
        padding-right: 0.75rem;

        .icon {
          margin-left: 0.5rem;
          margin-right: 0;
        }
      }
    }

    @media (max-width: 600px) {
      height: 2.5rem;

      &.round {
        width: 2.5rem;
      }
    }
  }

  &.tiny {
    height: 2.25rem;
    padding: 0.4375rem;
    border-radius: var(--border-radius-default-small);
    font-size: 0.875rem;
    font-weight: 500;

    &.round {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
    }

    .icon {
      font-size: 1.25rem;
      
    }

    &.pill {
      height: 2rem;
      border-radius: var(--border-radius-default-small);
      padding: 0.3125rem 1rem;
      font-size: 1rem;
    }
  }

  &.fluid {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    width: auto;

    &.tiny {
      padding-left: 1.375rem;
      padding-right: 1.375rem;
    }
  }

  &.pill {
    border-radius: 1.75rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    text-transform: none;
  }

  &.loading {
    position: relative;
    pointer-events: none;

    .Spinner {
      position: absolute;
      right: 0.875rem;
      top: 50%;
      transform: translateY(-50%);

      --spinner-size: 1.8125rem;
    }
  }

  .emoji {
    vertical-align: -3px;
  }

  &.shiny::before {
    content: "";
    position: absolute;
    top: 0;

    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, var(--color-skeleton-foreground) 50%, transparent 100%);
    animation: wave 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes wave {
      0% {
        transform: translateX(-100%);
      }
      50% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }

  &.premium {
    background: var(--premium-gradient);
  }

  &.rectangular {
    border-radius: 0;
  }
}

.hotkey-frame {
  align-items: flex-start;
  background-color: rgb(0 0 0 / 12%);
  border: 1px solid;
  border-color: #ffffff0a;
  border-radius: 2px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 3px;
  position: relative;
  margin-left: 6px;
  box-shadow: 1px 1px 0px 0px rgba(27, 26, 29, 0.25);

  .enter-hotkey-icon {
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTQ3MzUgNi43MUM5Ljk0NzM1IDcuMDAxNSA5LjcwODg1IDcuMjQgOS40MTczNSA3LjI0SDMuODA0NjVMNS4wMjM2NSA4LjQ1MzdDNS4yMzAzNSA4LjY2MDQgNS4yMzAzNSA4Ljk5OTYgNS4wMjM2NSA5LjIwNjNDNC45MTc2NSA5LjMwNyA0Ljc4NTE1IDkuMzYgNC42NDczNSA5LjM2QzQuNTA5NTUgOS4zNiA0LjM3NzA1IDkuMzA3IDQuMjcxMDUgOS4yMDYzTDIuMTUxMDUgNy4wODYzQzIuMTAzMzUgNy4wMzg2IDIuMDY2MjUgNi45NzUgMi4wMzk3NSA2LjkxMTRDMS45ODY3NSA2Ljc4NDIgMS45ODY3NSA2LjYzNTggMi4wMzk3NSA2LjUwODZDMi4wNjYyNSA2LjQ0NSAyLjEwMzM1IDYuMzgxNCAyLjE1MTA1IDYuMzMzN0w0LjI3MTA1IDQuMjEzN0M0LjQ3Nzc1IDQuMDA3IDQuODE2OTUgNC4wMDcgNS4wMjM2NSA0LjIxMzdDNS4yMzAzNSA0LjQyMDQgNS4yMzAzNSA0Ljc1OTYgNS4wMjM2NSA0Ljk2NjNMMy44MDQ2NSA2LjE4SDguODg3MzVWNC4wNkg3LjgyNzM1QzcuNTM1ODUgNC4wNiA3LjI5NzM1IDMuODIxNSA3LjI5NzM1IDMuNTNDNy4yOTczNSAzLjIzODUgNy41MzU4NSAzIDcuODI3MzUgM0g5LjQxNzM1QzkuNzA4ODUgMyA5Ljk0NzM1IDMuMjM4NSA5Ljk0NzM1IDMuNTNWNi43MVoiIGZpbGw9IiNFM0UzRUIiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .hotkey-text {
    font-size: 1rem;
    font-weight: 500;
    min-width: 16px;
    text-align: center;
    padding: 2px;
    color: var(--color-text-secondary);
    line-height: normal;
    align-items: center;
  }
}
