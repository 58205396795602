
.wrapper {
	$color: var(--color-interactive-element-hover);

	border-radius: 0.75rem;
	border: 1px solid $color;
	background: $color;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 20rem;
	min-width: 30rem;

	@media (max-width: 600px) {
		min-height: unset;
		min-width: unset;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 2.5rem;

	@media (max-width: 600px) {
		gap: 1.5rem;
	}
}

.children {
	display: flex;
	gap: 1rem;
}

.subtitle {
	color: var(--color-placeholders);;
	text-align: center;
	// font-family: Inter Tight;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */

	@media (max-width: 600px) {
		font-size: 0.5rem;
		line-height: 0.8125rem;
		height: 1rem;
	}
}