.workspaceCreator {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 2.5rem;
    color: var(----color-text);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 140% */
    top:auto;
    left:auto;
  }

  .desc {
    color: var(--color-text-secondary);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;  /* 143% */
    margin-top: 1rem;
    left:auto;
  }
    
    
  .workspaceInput {
    max-width: 480px;
    height: 48px;
    padding: 8px;
    color: var(--color-text);
    background: var(--color-interactive-element-hover);
    border-radius: 6px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .fileInput {
      display: none;
    }
  }

  .uploadButton {
    width: 32px;
    height: 32px;
    position: relative;
    border: dashed 1px;
    border-radius: 12px;
    border-color:  var(--color-text-secondary);
    display: flex;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
  }

  .uploadedImageContainer {
    width: 32px; /* Ширина контейнера */
    height: 32px; /* Высота контейнера */
    border-radius: 12px; /* Радиус границы */
    overflow: hidden; /* Скрывать часть изображения, выходящую за границы контейнера */
  }
  
  .uploadedImageContainer img {
    width: 100%; /* Ширина изображения */
    height: 100%; /* Высота изображения */
    object-fit: cover; /* Растягивает или сжимает изображение для заполнения контейнера */
    border-radius: 12px; /* Радиус границы для изображения */
  }

  .inputText {
    flex: 1 1 0;
    width: 100%;
    height: 24px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    background: transparent;
    border: none;
    color: var(--color-text) ;
    outline: none;
    caret-color: var(--color-primary);
  }

  .characterCount {
    width: 41px;
    text-align: right;
    color:  var(--color-text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

}

.saveButton {
  margin-top: 2.75rem;
  display: flex;
  width: 100%;
  padding: 0.75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.375rem;
  background: var(--color-interactive-element-hover);
  border: none !important;
  outline: none;

  .saveButton:not(:disabled) {
    cursor: pointer;
  }
}

.saveButton.active {
  cursor: pointer;
}

.saveButtonText {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; 
}


.saveButtonText.active {
  color: var(--color-primary); /* Укажите ваш активный цвет */
  cursor: pointer;
}

.deleteButton {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  padding: 0.75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  background: transparent;
  border-radius: 0.375rem;
  border: none !important;
  outline: none;
  cursor: pointer;
}

.deleteButton:hover {
  background: var(--color-background-compact-menu-hover);
}

.deleteButtonText {
  color: var(--color-error);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; 
  cursor: pointer;
}

.iconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}