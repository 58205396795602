// todo: set vars
[cmdk-dialog] {
  max-width: 640px;
  width: 80%;
  z-index: var(--z-portal-menu);
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);

  * {
    --scrollbarBG: transparent;
    --thumbBG: #fff4;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  *::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 2px;
    border: 2px solid var(--scrollbarBG);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}

.bounce-animation {
  animation: bounce 0.2s ease;
}

[cmdk-root] {
  max-width: 640px;
  width: 100%;
  padding: 8px;
  background: var(--color-cmdk);
  border-radius: 8px;
  /* backdrop-filter: blur(20px) saturate(190%) contrast(70%) brightness(80%); */
  overflow: hidden;
  font-family: var(--font-sans);
  border: 1px solid var(--color-borders);
  box-shadow: var(--cmdk-shadow);
  transition: transform 100ms ease;

  @media (max-width: 1200px){
    background: var(--color-background-no-blur);
  }
}


.theme-light [cmdk-root] {
  backdrop-filter: blur(25px) saturate(190%) contrast(50%) brightness(130%);
}

.theme-dark [cmdk-root] {
  backdrop-filter: blur(23px) saturate(190%) contrast(70%) brightness(80%);
}

[cmdk-input] {
  font-family: var(--font-sans);
  border: none;
  width: 100%;
  font-size: 17px;
  padding: 8px 8px 16px 8px;
  outline: none;
  background: var(--bg);

  color: var(--color-text);
  border-bottom: 1px solid var(--color-borders);
  margin-bottom: 16px;
  border-radius: 0;

  &::placeholder {
    color: var(--color-text-secondary);
  }
}

[cmdk-vercel-badge] {
  height: 20px;
  background: var(--color-interactive-element-hover);
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  color: var(--color-text-secondary);
  border-radius: 4px;
  margin: 4px 0 4px 4px;
  user-select: none;
  text-transform: capitalize;
  font-weight: 500;
}

[cmdk-item] {
  content-visibility: auto;

  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: background 150ms ease, color 150ms ease;
  transition-property: none;

  &[data-selected='true'] {
    background: var(--color-interactive-element-hover);

    .icon {
      color: var(--color-text);
      font-size: 16px;
    }
  }

  &[data-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--color-interactive-element-hover);
  }

  & + [cmdk-item] {
    margin-top: 4px;
  }

  .icon {
    color: var(--color-text-secondary);
    font-size: 16px;
  }

  .image {
    height: 1.25rem;
    width: 1.25rem;
    background-size: cover;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    width: 20px;
    height: 20px;
    background: var(--color-interactive-element-hover);
    color: var(--color-text);
    font-size: 0.8125rem;
    font-weight: bold;
  }
  

  // svg {
  //   width: 18px;
  //   height: 18px;
  // }
}

[cmdk-list] {
  height: min(330px, calc(var(--cmdk-list-height)));
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: height 100ms ease; /* stylelint-disable-line */
}

[cmdk-vercel-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;

  kbd { /* stylelint-disable-line */
    font-family: var(--font-sans);
    font-size: 12px;
    min-width: 20px;
    padding: 4px;
    height: 20px;
    border-radius: 4px;
    color: var(--gray11);
    background: var(--gray4);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

[cmdk-separator] {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}

*:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}

[cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: var(--color-text-secondary);
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

[cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  white-space: pre-wrap;
  color: var(--gray11);
}

.global-search {
  display: none;
}

/* Показать элемент глобального поиска, когда нет результатов */
[cmdk-empty] + .global-search {
  content-visibility: auto;

  cursor: pointer;
  background: none;
  width: 100%;
  border: none;
  margin: 0;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px !important;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: background 150ms ease, color 150ms ease;
  transition-property: none;

  &[data-selected='true'] {
    background: var(--color-interactive-element-hover);
  }

  &[data-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--color-interactive-element-hover);
  }

  & + [cmdk-item] {
    margin-top: 4px;
  }
}

.global-search:hover, .global-search[data-selected='true'] {
  /* Ваши стили для hover, например изменение фона */
  background: var(--color-interactive-element-hover); /* Пример фона при наведении */
}

.cmdk-backdrop {
  position: fixed;
  left: -100vw;
  right: -100vw;
  top: -100vh;
  bottom: -100vh;
  z-index: var(--z-menu-backdrop);
}

.shortcuts {
  display: flex;
  margin-left: auto;
  gap: 0.1875rem;

  .kbd {
    font-family: var(--font-sans);
    display: inline-block;
    text-align: center;
    color: var(--color-text);
    background-color: var(--color-chat-active);
    border: 1px solid;
    border-color: var(--color-borders);
    border-radius: 3px;
    position: relative;
    box-shadow: 1px 1px 0px 0px var(--color-default-shadow);
    padding: 4px 3px 4px 4px;
    vertical-align: baseline;
    text-transform: capitalize;
    font-size: 11px;
    line-height: 110%;
    min-width: 20px;
  }
}

.user-handle {
margin-left: 0.625rem;
color: var(--color-text-secondary);
 }

.chat-status {
margin-left: 0.625rem;
color: var(--color-text-secondary);
  }


[cmdk-pinned] {
  margin-left: auto;
  font-size: 20px;

  .icon {
    font-size: 20px;
  }
}

[cmdk-selected] {
  margin-left: auto;
  font-size: 20px;

  .icon {
    font-size: 20px;
  }
}