.UluHeaderProfile-module__photoWrapper .ProfilePhoto .thumb:not(.shown) {
  display: none !important;
}

.wrapper {
  --custom-cursor: default;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem 0.375rem 0.375rem;
  border-radius: var(--border-radius-default-tiny);
  cursor: var(--custom-cursor, pointer);
  
  &.actionable {
    --custom-cursor: pointer;

    &:hover {
      background-color: var(--color-interactive-element-hover);
  
      .userName {
        color: var(--color-text);
      }
    }
  }
}

.photoWrapper {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    width: 20px;
  }
  
  :global(.avatar-media) {
    border-radius: 0.5rem;
    vertical-align: inherit;
  }

  :global(.no-photo) {
    border-radius: inherit;
    font-size: 0.6875rem;
    white-space: nowrap;
  }
}


.userName {
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  width: 50%;
  white-space: nowrap;
  min-width: 125px;
}