.wrapper {
  display: flex;
  gap: 10px;
  cursor: pointer;
  pointer-events: auto;

  .icon {
    font-size: 1.5rem;
    color: var(--color-text-secondary);
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    width: 2rem;
    height: 2rem;
    gap: 0.5rem;
    align-self: stretch;
    pointer-events: pointer;
    justify-content: center;

    &:hover {
      border-radius: 0.375rem;
      background: var(--color-interactive-element-hover);
      color: var(--color-error);
      cursor: pointer;
  
      .icon {
        color: var(--color-text);
      }
  
    }
  }
}