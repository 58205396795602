.MenuItem {
  width: 100%;
  background: none;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  position: relative;
  overflow: hidden;
  line-height: 1.5rem;
  white-space: nowrap;
  color: var(--color-text);
  --ripple-color: rgba(0, 0, 0, 0.08);
  cursor: var(--custom-cursor, pointer);
  unicode-bidi: plaintext;

  .right-badge {
    min-width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    background: var(--color-gray);
    border-radius: 0.75rem;
    padding: 0 0.4375rem;
    color: white;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    flex-shrink: 0;
  }

  &.compact .right-badge {
    background: none;
    padding: 0;
    color: var(--color-text-secondary);
  }

  &[dir="rtl"] .right-badge {
    margin-left: 0;
    margin-right: auto;
  }

  @media (hover: hover) {
    // &:focus 
    &:hover
    {
      background-color: var(--color-chat-hover);
      text-decoration: none;
      color: inherit;
    }
  }

  @media (max-width: 600px) {
    // &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      color: inherit;
    }

    &:active {
      background-color: var(--color-chat-hover);
    }
  }

  .icon {
    font-size: 1.5rem;
    margin-right: 2rem;
    color: var(--color-icon-secondary);
  }

  .menu-item-name {
    margin-right: 2rem;

    &.capitalize {
      text-transform: capitalize;
    }
  }

  &.disabled {
    opacity: 0.5 !important;
    cursor: var(--custom-cursor, default) !important;
  }

  &.destructive {
    color: var(--color-error);

    .icon {
      color: inherit;
    }
  }

  &:not(.has-ripple):not(.disabled):not(.compact):active {
    background-color: var(--color-item-active);
    transition: none !important;
  }

  .Switcher, .menu-item-badge {
    margin-left: auto;
  }

  .menu-item-badge {
    margin-right: 0.25rem;
    font-size: 0.75rem;
    color: var(--color-primary);
    font-weight: normal;
    line-height: normal;
  }

  &[dir="rtl"] {
    .icon {
      margin-left: 2rem;
      margin-right: 0;
    }

    .menu-item-name {
      margin-left: 2rem;
      margin-right: 0;
    }

    > .Switcher {
      margin-left: 0;
      margin-right: auto;
    }
  }

  &.compact {
    font-size: 0.875rem;
    padding: 0.375rem;
    border-radius: 0.375rem;
    width: auto;
    font-weight: 500;
    transform: scale(1);
    transition: 0.15s ease-in-out transform;

    &.compact.secondary {
      color: var(--color-text-secondary);

      &:hover {
        color: var(--color-text);
      }

      .icon {
        color: inherit;
      }
    }

    &.compact.selected {
      background: var(--color-primary);
      color:  var(--color-text);

      &:hover {
        background: var(--color-primary);
        color:  var(--color-text);
      }

      .icon {
        color: inherit;
      }
    }

    .icon {
      max-width: 1.25rem;
      font-size: 1.25rem;
      margin-right: 0.5rem;

      &::before {
        max-width: 1.25rem;
      }

      &.icon-check {
        font-size: 1.25rem;
        color: var(--color-primary);
        margin-left: auto;
      }
    }

    @media (hover: hover) {
      &:hover,
      // &:focus,
      &:active {
        background: var(--color-background-compact-menu-hover);
        text-decoration: none;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }

  b {
    font-weight: 600;
  }

  &.wrap {
    display: block;
    white-space: normal;
  }

  &.menu-custom-emoji-sets {
    margin: 0 0.25rem;
    padding: 0.5rem 0.75rem;
    font-weight: 400;
    font-size: small;
    line-height: 1.125rem;
  }

  .ProfilePhoto {
    height: 1.25rem;
    width: 1.25rem;
    background-size: cover;
    border-radius: 6px;
    margin-right: 0.5rem;
    overflow: hidden;
    background-repeat: no-repeat;
    vertical-align: inherit;

    :global(.no-photo) {
      border-radius: inherit;
      font-size: 10px;
      white-space: nowrap;
    }
  }
}

.MenuItem.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem; /* или используйте var(--border-radius-default-tiny) если это уместно */
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.094);; /* Серый фон */
    color: rgba(255, 255, 255, 0.443); /* Цвет текста */
    font-size: 1rem; /* Размер шрифта для первой буквы */
    font-weight: bold;
    margin-right: 0.5rem;
}

.secondary-item {
  color: var(--color-text-secondary);
}

.shortcut {
  display: flex;
  height: 1.125rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--color-text-secondary);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9375rem; /* 125% */
  margin-left: auto;
  padding: 0.375rem;
}
