.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-confetti);

  pointer-events: none;
  overflow: hidden;
}
