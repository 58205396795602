.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: opacity 0.25s;

	&.fade {
		opacity: 0;
	}
}

.title {
	margin-top: 2.5rem;
	color: var(--color-text);
	text-align: center;
	font-size: 2rem;
	font-style: normal;
	font-weight: 600;
	line-height: 2.5rem; /* 125% */
}